<template>
  <LoginLayout>
    <div class="login-item">
      <header class="login-header">
        <h1 class="login-headline">ログイン</h1>
      </header>
      <div class="login-container">
        <form>
          <div class="form">
            <div class="form-row">
              <p class="form-label">ユーザーID</p>
              <div class="form-content">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.clientId }"
                  type="text"
                  name="email"
                  v-model="clientId"
                  v-email
                  v-trim
                />
              </div>
            </div>
            <div class="form-row">
              <p class="form-label">パスワード</p>
              <div class="form-content">
                <div class="form-content-row">
                  <div class="form-password">
                    <input
                      v-trim
                      v-model="clientSecret"
                      :class="{ 'form-control form-password-input': true, 'is-error': errors.clientSecret }"
                      name="password"
                      :type="pwdVisible ? 'text' : 'password'"
                    />
                    <label class="form-check form-password-toggle">
                      <input class="form-check-input" type="checkbox" value="" name="" v-model="pwdVisible" />
                      <i class="aikon form-password-icon"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <label class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  name=""
                  v-model="rememberFlag"
                />
                <span class="form-check-label">次回から自動的にログイン</span>
              </label>
            </div>
          </div>
          <ActionButton class="btn btn-w100 btn-blue" :handle-submit="login" button-text="ログイン" />
        </form>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import error from '@/mixins/plugin/error';
import password from '@/mixins/plugin/password';
import { RouterConstants } from '@/constants/router';
import LoginLayout from '@/components/LoginLayout.vue';

export default {
  name: 'Login',
  data: function () {
    return {
      clientId: null,
      clientSecret: null,
      rememberFlag: false,
    };
  },
  validations: {
    clientId: { required },
    clientSecret: { required }
  },
  components: {
    LoginLayout,
  },
  mixins: [error, password],
  computed: {
    ...mapGetters({
      userRole: 'auth/userRole',
      infor: 'auth/infor',
      passwordExpiresAt: 'auth/passwordExpiresAt',
      isPasswordExpired: 'auth/isPasswordExpired',
      authEmailFlag: 'auth/authEmailFlag'
    })
  },
  created () {
    this.$store.dispatch('common/setLoading', false);
    if (this.$route.params?.isOutOfSession) {
      this.$nextTick(() => {
        this.$message.showError('outOfLoginSession');
      });
    }
  },
  methods: {
    async login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v);
      } else {
        const result = await this.$store.dispatch("auth/login", {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          rememberFlag: this.rememberFlag,
        });
        if (result) this.checkAuthorization();
      }
    },
    async checkAuthorization() {
      if (this.authEmailFlag) {
        this.$router.push({ name: 'PasswordCode' });
      } else if (!this.passwordExpiresAt) {
        this.$router.push({ name: 'PasswordDefault' });
      } else if (this.isPasswordExpired) {
        this.$router.push({ name: 'PasswordReset' });
      } else {
        await this.$store.dispatch("auth/getUserInfor");
        if (this.userRole) {
          if(this.$permission.isGmoOrOffice()) {
            this.$router.push({ name: RouterConstants.DEFAULT_ROUTER_OF_MASTER_OR_CLIENT });
          }
          if(this.$permission.isStoreOrGroup()) {
            await this.$store.dispatch("event/getParentEventDetail", this.infor.event?.subdomain);
            this.$router.push({ name: RouterConstants.DEFAULT_ROUTER_OF_SHOP_OR_GROUP });
          }
        }
      }
    }
  },
};
</script>
